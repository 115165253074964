<template>
    <div class="flex h-full flex-col">
        <AppHeader />

        <main>
            <slot />
        </main>

        <AppFooter />
    </div>
</template>
